/* CSS Document */
body {
	padding: 0;
	margin: 0;
	background-color: #ffffff;
	color: #333333;
	font-family: Arial, Helvetica, sans-serif;
}

/* BASIC TEXT FORMATTING */
div,h1,h2,p,form,label,input,textarea,img,span {
	margin: 0;
	padding: 0;
}
section, aside, article{
    display: block;
}
section footer{
	margin:0;
	padding:0;
	width:auto;
	display:block;
}
.clear{
    clear: both;
}

/* General */

a,a:link a:active {
	color: #59c9ca;
	text-decoration: none;
	border: none;   /* fix for IE8 */
	outline:none;  /* fix for IE8 */
}
a:visited {
	background-color: inherit;
	border: none;   /* fix for IE8 */
	outline:none;  /* fix for IE8 */
}
a:hover {
	text-decoration: underline;
	border: none;   /* fix for IE8 */
	outline:none;  /* fix for IE8 */
}
a img {
	border: none;
}
a.dark {
	color: #268586;
	text-decoration: underline;
}
img {
	border:none;
}
h1 {
    color: #645844;
    font: bold 28px/1.4em "Jura","sans-serif";
	line-height: normal;
	margin: 10px 0 0 0;
}
h1.main_h1{
	border-bottom: 1px solid #59C9CA;
}
h2 {
	color: #645844;
    font: bold 18px/1.4em "Jura","sans-serif";
	line-height: 1.8em;
	margin: 0 0 5px;
	text-transform: uppercase;
}
h3 {
	color: #2F9B7D;
    font: bold 16px/1.2em "Jura","sans-serif";
	line-height: 1.6em;
	margin: 0 0 10px;
}
h4 {
	color: #645844;
    font: bold 14px/1.0em "Jura","sans-serif";
	line-height: 1.4em;
	margin: 0;
}
p {
	font-size: 14px;
	line-height: 18px;
	margin: 0;
	padding: 0 0 15px 0;
}
ul {
	list-style: square outside;
	margin-left: 0;
	padding-left: 35px;
	margin-top: 25px;
}
ul li {
	font-size: 12px;
	margin: 0 0 15px;
	padding: 0;
}
.center{
	text-align:center;
}
.spacer {
	clear: both;
	font-size: 0;
	line-height: 0;
}


/* LAYOUT FOR ALL PAGES */

#root {
	display: block;
	width: 1150px;
	min-height: 971px;
	margin: 0 auto;
	background-color: #fff;
}
#content_root {
    display: flex;
	flex-direction: column;
	min-height: 900px;
}

/* HEADER STYLE */

#portfolioHeader {
	display: flex;
	flex-direction: row;
	font: 16px/1.4em "Jura","sans-serif";
	margin-top: 30px;
}


#name {
	margin: 17px 10px 10px 10px;
}
#name a:hover {
	text-decoration: none;
}
#mainNavigation {
	margin-left: auto;
}

#top_name:hover {
	text-decoration: none;
	color: #000000;
}

/* MAIN MENU STYLE */

.mainmenu {
	font-weight: 500;
	height: 39px;
	line-height: 26px;
	list-style-image: none;
	list-style-position: outside;
	list-style-type: none;
	margin-top: 15px;
	padding: 0;
}
.mainmenu li {
	display: block;
	float: left;
	margin: 0;
	padding: 0 0 0 25px;
	height: 39px;
	list-style-image: none;
}
.mainmenu li a {
    color: #3a3a3a;
	display: block;
	text-align: left;
	text-decoration: none;
	font-size: 16px;
	padding: 0 15px;
}
.mainmenu a:hover,.mainmenu a.active,.mainmenu a.active:hover {
	color: #59c9ca;
}
.mainmenu li a.active:active,
.mainmenu li a:visited,
.mainmenu li a:hover,
.mainmenu li a:active {
	border:none;
	outline:none;
}

/* Homepage */

#my_logo, #design_info {
	margin: 30px auto;
	width: 400px;
}

#design_info p {
    font: 28px/1.8em "Jura","sans-serif";
    margin-bottom: 80px;
}

/* About and Contact pages*/

.about_container,
.contact_container {
	display: flex;
	flex-direction: row;
}
.about_me_text h2 {
	text-transform: uppercase;
}
.quote_block {
	display: flex;
	flex-direction: row;
	gap: 20px;
}
.quote_text {
	font-style: italic;
	font-size: 14px;
	font-weight: 600;
}
.quote_author {
	text-align-last: end;
	font-size: 14px;
}

/* Contact page */

.contact_img {
	margin-left: auto;
}
.formContainer {
	border-radius: 5px;
	background-color: #f2f2f2;
	padding: 20px;
	width: 700px;
}
input[type=text], 
input[type=email], 
textarea {
	width: 100%; 
	padding: 12px;  
	border: 1px solid #ccc; 
	border-radius: 4px;
	box-sizing: border-box; 
	margin-top: 6px; 
	margin-bottom: 16px;
	resize: vertical;
}  
.errorText {
	color: tomato;
	margin-bottom: 30px;
}
.submitButton {
	width: 270px;
	height: 80px;
	border: none;
	outline: none;
	background: #2f2f2f;
	color: #fff;
	font-size: 22px;
	border-radius: 40px;
	text-align: center;
	box-shadow: 0 6px 20px -5px rgba(0,0,0,0.4);
	position: relative;
	overflow: hidden;
	cursor: pointer;
	margin: 20px auto;
	display: block;
}
p#buttonText {
	font-size: 16px;
	font-weight: 500;
	line-height: 80px;
}
.check-box {
	width: 80px;
	height: 80px;
	border-radius: 40px;
	box-shadow: 0 0 12px -2px rgba(0,0,0,0.5);
	position: absolute;
	top: 0;
	right: -40px;
	opacity: 0;
}

.check-box svg {
	width: 40px;
	margin: 20px;
}

svg path {
	stroke-width: 3;
	stroke: #fff;
	stroke-dasharray: 34;
	stroke-dashoffset: 34;
	stroke-linecap: round;
}
.formContainer
.active {
	background: #59c9ca;
	font-weight: 600;
	font-size: 16px;
	transition: 1s;
}
.formContainer
.active .check-box{
	right: 0;
	opacity: 1;
	transition: 1s;
}
.formContainer
.active p {
	margin-right: 125px;
	transition: 1s;
}
.formContainer
.active svg path{
	stroke-dashoffset: 0;
	transition: 1s;
	transition-delay: 1s;
}
.follow {
	margin-top: 50px;
	margin-left: 95px;
}
.follow_me {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}


/* SEARCH BAR STYLE */

.searchAbility {
	border: 1px dashed #2F9B7D;
	padding: 50px;
	margin: 30px 70px;
	background-color: #59c9ca;
}
.searchAbilityContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
    align-items: center;
}
.searchInputArea input {
	padding: 10px;
	width: 300px;
	color: gray;
}
.searchInputArea input:focus {
	outline: none;
}
.searchInputArea button {
	background-color: rgb(70, 69, 69);
	color: white;
	border-radius: 12px;
	padding: 13px;
	border: 0;
	width: 90px;
	margin-left: 10px;
}
.searchResponse {
	width: 50vw;
	text-align: center;
}
.searchResponseDiv,
.negativeAnswerDiv {
	display: inline-block;
	background-color: #2F9B7D;
	color: white;
	padding: 5px;
	border-radius: 3px;
	margin-right: 5px;
	margin-bottom: 5px;
}
.negativeAnswerDiv {
	background-color: tomato;
}
.allAbilities {
	display: flex;
	flex-direction: row;
	column-gap: 100px;
	justify-content: space-around;
}

/* Projects page */

.projectListItem {
	display: flex;
	flex-direction: row;
	gap: 50px;
	margin-top: 50px;
	align-items: center;
}

/* Contact page */

.gray_text{
	line-height: 17px;
	padding-bottom: 0;
	font-weight: bold;
}
#contact_page table span{
	font: 13px/1.4em "Helvetica","Arial","sans-serif";
    padding: 0 10px 5px 0;
    text-align: right;
    vertical-align: top;
    width: 70px;
}
.underlined_section{
	border-bottom: 1px solid #3a3a3a; 
	margin-top: 30px;
}	

/* FOOTER STYLE */

footer {
	margin-top: auto;
}
#copyright {
	text-align: center;
	height: 15px;
	padding: 5px 0;
	font-size: 10px;
}
#social_links{
	margin: 0 auto;
	display: block;
	height: 30px;
	width: 150px;
} 
#social_links span a {
	margin-right: 2px;
}
@media (max-width:1150px) {
	#root {
		width: auto;
	}
	#portfolioHeader {
		flex-direction: column;
	}
	#mainNavigation {
		margin-left: 0;
		margin-bottom: 20px;
		background-color: #2f2f2f;
	}
	.mainmenu li {
		padding: 0;
	}
	.mainmenu li a {	
		color:#59c9ca; 
		font-weight: 800;
	}
	.mainmenu a.active, 
	.mainmenu a:hover  {	
		color:white; 
	}
	section {
		margin-left: 10px;
	}
	.contact_img {
		display: none;
	}
	.contact_container {
		flex-direction: column;
		align-items: center;
	}
}
@media (max-width:800px) {
	secttion {
		margin-right: 10px;
	}
	.allAbilities {
		flex-direction: column;
		text-align: center;
	}
	.searchInputArea input {
		width: 200px;
	}
}
@media (max-width:770px) {
	.formContainer {
		width: 550px;
	}
}
@media (max-width:680px) {
	.about_me_img {
		display: none;
	}
	article {
		margin-right: 10px;
	}
	footer {
		margin-top: 100px;
	}
}
@media (max-width:600px) {
	.formContainer {
		width: 400px;
	}
}
@media (max-width:485px) {
	#name {
		display: none;
	}
	#portfolioHeader {
		margin-top: 0;
	}
	.mainmenu {
		height: auto;
	}
	.mainmenu li {
		float: none;
		border-bottom: 1px solid rgb(71, 69, 69);
	}
	.mainmenu li:last-child {
		border-bottom: 0;
	}
	.mainmenu li a {
		text-align: center;
	}
	.contact_text {
		margin-left: 10px;
	}
}
@media (max-width:428px) {
	.formContainer {
		width: 327px;
	}
}
@media (max-width:375px) {
	.formContainer {
		width: 300px;
	}
}